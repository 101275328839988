import React from "react";
import linkedinIcon from "../assets/linkedin-icon.svg";
import githubIcon from "../assets/github-icon.svg";
import resume from "../assets/file-icon.svg";
import mailIcon from "../assets/mail-icon.svg";
const Topbar = () => {
  return (
    <div class="card">
      <p style={{ fontFamily: "BoldCondensed", fontSize: "20pt" }}>
        SOFTWARE DEVELOPER
      </p>
      <div className="row justify-content-center">
        <div className="col-6">
        <a
          href="https://www.linkedin.com/in/mahmut-can-tuncer/"
          style={{ color: "#0A66C2" }}
          className="social-media-text"
          target="_blank"
          rel="noreferrer"
        >
          <p>
            <img src={linkedinIcon} width="30" height="30" alt="linkedin" />
            <span className="social-media">Linkedin</span>
          </p>
        </a>

        <a
          href="https://github.com/mahmutcant"
          className="social-media-text"
          target="_blank"
          rel="noreferrer"
        >
          <p>
            <img src={githubIcon} width="30" height="30" alt="linkedin" />
            <span className="social-media">Github</span>
          </p>
        </a>
        <a
          href={require("../assets/mahmut-can-tuncer-cv-en.pdf")}
          download="mahmut-can-tuncer-cv-en"
          className="social-media-text"
          style={{marginBottom:"20px"}}
        >
          <p>
            <img src={resume} width="30" height="30" alt="linkedin" />
            <span className="social-media">CV</span>
          </p>
        </a>
        </div>
        <div className="col-6">
        <a
          href="mailto:mahcan012@gmail.com"
          className="social-media-text"
          target="_blank"
          rel="noreferrer"
        >
          <p>
            <img src={mailIcon} width="30" height="30" alt="linkedin" />
            <span className="social-media">E-Mail</span>
          </p>
        </a>
        </div>
      </div>
      <p class="card-footer">Mahmut Can Tuncer</p>
    </div>
  );
};

export default Topbar;
